const bringToFront = (el) => {
  const highestZIndex = Array.from(document.querySelectorAll('.draggable')).reduce(
    (maxZIndex, element) => {
      const zIndex = parseInt(window.getComputedStyle(element).zIndex, 10) || 1;
      return Math.max(maxZIndex, zIndex);
    },
    0
  );
  el.style.zIndex = highestZIndex + 1;
};

export const draggableDirective = {
  mounted(el) {
    el.classList.add('draggable');
    el.style.zIndex = 100;
    el.style.boxShadow = 'rgba(0, 0, 0, 0.16) 0px 1px 4px';
    
    bringToFront(el);
    el.style.position = 'absolute';

    let initX, initY, clickX, clickY;
    let isResizing = false; // Flag to check if resizing is in progress

    const mouseUp = () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('mouseup', mouseUp);
      el.style.cursor = 'default';
    };

    const mouseMove = (e) => {
      if (!isResizing && !e.target.classList.contains('no-drag')) {
        bringToFront(el);

        const maxX = window.innerWidth - 320 - (el.style.width?.includes('px') ? Number(el.style.width.slice(0, -2)) : el.offsetWidth);
        const maxY = window.innerHeight - 70 - (el.style.height?.includes('px') ? Number(el.style.height.slice(0, -2)) : el.offsetHeight);

        let newX = initX + e.clientX - clickX;
        let newY = initY + e.clientY - clickY;

        newX = Math.max(0, Math.min(newX, maxX));
        newY = Math.max(0, Math.min(newY, maxY));
        el.style.left = `${newX}px`;
        el.style.top = `${newY}px`;
      }
    };

    const mouseDown = (e) => {
      e.preventDefault();
      initX = el.offsetLeft;
      initY = el.offsetTop;
      clickX = e.clientX;
      clickY = e.clientY;
      el.style.cursor = 'grabbing';
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    };

    el.addEventListener('mousedown', (e) => {
      if (!isResizing) {
        mouseDown(e);
      }
    });

    el.addEventListener('resizestart', () => {
      isResizing = true;
    });

    el.addEventListener('resizeend', () => {
      isResizing = false;
    });

    el.addEventListener('dblclick', () => {
      bringToFront(el);
    });
  },
};
