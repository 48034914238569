import { api } from 'boot/axios';

class DefaultApi {
    static get(id) {
        return api.get(this.url + (id ?? ''));
    }
    static create(payload) {
        return api.post(this.url, payload);
    }
    static update(id, payload) {
        return api.put(this.url + id, payload);
    }
    static delete(id) {
        return api.delete(this.url + id);
    }
}

export class MeshBridgeApi extends DefaultApi {
    static url = '/mesh_bridge/';
}

export class WarehouseApi {
    static url = '/location-management/warehouses/';
    static get() {
        return api.get(this.url);
    }
    static create(payload) {
        return api.post(this.url, payload);
    }
    static update(id, name, payload) {
        return api.put(this.url + id + '/' + name, payload);
    }
    static delete(id) {
        return api.delete(this.url + id);
    }
}

export class RemoteLocationApi extends WarehouseApi {
    static url = '/location-management/customer-remote-location/';
}

export class NodeApi {
    static url = '/node_states/';
    static get(payload) {
        const [filter = 'ALL', attr = ['ALL']] = payload ?? [];
        return api.get(this.url + filter + '/' + attr.join());
    }
}

export class AlertApi {
    static url = '/alerts/';
    static get(payload) {
        const { dateFrom, dateTo, params } = payload;

        return api.get(this.url + dateFrom + '/' + dateTo, {
            params: {
                severity: params.severity.length ? params.severity.join() : null,
                category: params.category.length ? params.category.join() : null
            }
        });
    }
}
export class LoginApi {
    static url = '/login/';
    static get() {
        return api.get(this.url);
    }
}

export class LabelApi {
    static url = '/label-management/labels/';
    static get(id) {
        return api.get(this.url + (id ?? 'ALL'));
    }
    static create(payload) {
        return api.post(this.url, payload);
    }
    static update(payload) {
        return api.put(this.url, payload);
    }
    static delete(id) {
        return api.delete(this.url + id);
    }
}

export class TrackerApi {
    static url = '/label-management/tracker/';
    static get(id) {
        return api.get(this.url + (id ?? 'ALL'));
    }
    static create(payload) {
        return api.post(this.url, payload);
    }
    static update(payload) {
        return api.put(this.url, payload);
    }
    static delete(id) {
        return api.delete(this.url + id);
    }
}

export class TransportApi extends LabelApi {
    static url = '/label-management/transport/';
}

export class ShipmentApi extends DefaultApi {
    static url = '/label-management/shipment/';
}

export class DefaultParametersApi {
    static url = '/default_parameters/';
    static get() {
        return api.get(this.url);
    }
    static update(payload) {
        return api.put(this.url, payload);
    }
}

export class LocalizationApi extends DefaultApi {
    static url = '/localization/';
}

export class LocalizationHistoryApi {
    static url = '/localization/zonal_history';
    static get(payload) {
        const { labelID, warehouseID, duration } = payload;
        return api.get(`${this.url}/${labelID}/${warehouseID}/${duration}`);
    }
}

export class MobileAnchorApi {
    static url = '/mobile_anchor_states';
    static get(warehouseID) {
        return api.get(`${this.url}/${warehouseID}`);
    }
}

export class MobileTrackerHistroyApi {
    static url = 'node_states/mobile_tracker';
    static get(payload) {
        return api.get(`${this.url}/${payload.mobile_tracker_id}/${payload.start_time}/${payload.duration}`);
    }
}

export class DeviceHistoryApi {
    static url = 'device_history';
    static get(payload) {
        return api.get(`${this.url}/${payload.device_type}/${payload.node_id}/${payload.start_time}/${payload.duration}`);
    }
}

export class EventsApi {
    static url = 'events';
    static get(payload) {
        const param = payload.join(',');
        return api.get(`${this.url}/${param}`);
    }
}
