import { createStore } from 'vuex';
import uiState from './ui-state';
import nodeState from './node-state';
import dashboard from './dashboard';

export default function (/* { ssrContext } */) {
    const Store = createStore({
        modules: {
            uiState,
            nodeState,
            dashboard
        },

        // enable strict mode (adds overhead!)
        // for dev mode and --debug builds only
        strict: process.env.DEBUGGING
    });

    return Store;
}
