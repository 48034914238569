export const setAccessToken = (state, accessToken) => {
    state.accessToken = accessToken;
};

export const setUserInfo = (state, data) => {
    state.userInfo = data;
};

export const updateLocationsType = (state, locationType) => {
    state.labelStatusLocationsType = locationType;
};

export const editLocation = (state, location) => {
    state.locationEdit = location;

    if (location) {
        state.locationsEditDraw = true;
    }
};

export const setLocationField = (state, field) => {
    state.locationEdit = {
        ...state.locationEdit,
        ...field
    };
};

export const toggleSidebarSettings = (state, data) => {
    state.sidebarSettings = data;
};

export const setDefaultParameters = (state, data) => {
    state.defaultParameters = data;
};

export const setNotification = (state, data) => {
    state.notifications.push(data);
};

export const deleteNotification = (state, id) => {
    const index = state.notifications.findIndex(n => n.id === id);
    if (index > -1) {
        state.notifications[index].close();
        state.notifications.splice(index, 1);
    }
};

export const setMapFilterType = (state, data) => {
    state.mapFilterType = data;
};

export const setMapFilterList = (state, data) => {
    const index = state.mapFilterList.findIndex(n => n === data);

    if (index > -1) {
        state.mapFilterList.splice(index, 1);
    } else {
        state.mapFilterList.push(data);
    }
};

export const setPreventMapFocus = (state, data) => {
    state.mapPreventFocus = data;
};

export const setMapLastPosition = (state, data) => {
    state.mapLastPosition = data;
};

export const setIndoorHistory = (state, data) => {
    state.indoorHistory = data;
};

export const setIndoorClusters = (state, data) => {
    state.indoorClusters = data;
};
